import keyMirror from "keymirror";
export const actions = keyMirror({
    // LOGIN MODULE
    "DONE_CHECK_LOGIN_STATUS": null,
    "DONE_GET_COGNITO_CREDENTIALS": null,
    "DONE_LOGOUT": null,
    "DONE_USER_INITALIZED": null,
    "CHECK_LOGIN_STATUS": null,
    "LOGIN": null,
    "LOGOUT": null,

    // PAGE MODULE
    "PAGE_CHANGE_MODE": null,
    "PAGE_CREATE": null,
    "PAGE_CREATE_SUCCESS": null,
    "PAGE_CREATE_FAILURE": null,

    "PAGE_EDIT": null,
    "PAGE_EDIT_SUCCESS": null,
    "PAGE_EDIT_FAILURE": null,

    "PAGE_LOAD": null,
    "PAGE_LOAD_FAILURE": null,
    "PAGE_LOAD_SUCCESS": null,

    "PAGE_DELETE": null,
    "PAGE_DELETE_FAILURE": null,
    "PAGE_DELETE_SUCCESS": null,

    "PAGE_REORDER": null,
    "PAGE_REORDER_FAILURE": null,
    "PAGE_REORDER_SUCCESS": null,

    // CATEGORY MODULE
    "CATEGORY_CHANGE_MODE": null,
    "CATEGORY_CREATE": null,
    "CATEGORY_CREATE_FAILURE": null,
    "CATEGORY_CREATE_SUCCESS": null,

    "CATEGORY_EDIT": null,
    "CATEGORY_EDIT_SUCCESS": null,
    "CATEGORY_EDIT_FAILURE": null,

    "CATEGORY_DELETE": null,
    "CATEGORY_DELETE_FAILURE": null,
    "CATEGORY_DELETE_SUCCESS": null,

    "CATEGORY_REORDER": null,
    "CATEGORY_REORDER_FAILURE": null,
    "CATEGORY_REORDER_SUCCESS": null,

    // ITEM MODULE
    "ITEM_CHANGE_MODE": null,
    "ITEM_CREATE": null,
    "ITEM_CREATE_FAILURE": null,
    "ITEM_CREATE_SUCCESS": null,

    "ITEM_EDIT": null,
    "ITEM_EDIT_SUCCESS": null,
    "ITEM_EDIT_FAILURE": null,

    "ITEM_MARK_COMPLETE": null,
    "ITEM_MARK_COMPLETE_FAILURE": null,
    "ITEM_MARK_COMPLETE_SUCCESS": null,

    "ITEM_DELETE": null,
    "ITEM_DELETE_FAILURE": null,
    "ITEM_DELETE_SUCCESS": null,

    "ITEM_REORDER": null,
    "ITEM_REORDER_FAILURE": null,
    "ITEM_REORDER_SUCCESS": null,
});
