import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import {
    changeCategoryMode,
    editCategory,
    createCategory,
    deleteCategory
} from "../modules/categories";
import Items from "./items";

const mapDispatchToProps = dispatch => bindActionCreators({
    changeCategoryMode,
    editCategory,
    createCategory,
    deleteCategory
}, dispatch);

const mapStateToProps = state => {
    return {
        categories: state.categories.categories,
        currentPage: state.pages.currentPage,
        mode: state.categories.mode,
        processing: state.categories.processing,
        failed: state.categories.failed,
        userVerified: state.login.userVerified 
    };
};

const Categories = props => {

    // TODO Make categories sortable
    // TODO Move category to different page (maybe drag and drop)

    const { categories, mode, userVerified, currentPage, failed, processing } = props;

    const [name, setName] = useState("");
    const [currentCategory, setCurrentCategory] = useState("");
    const [error, setError] = useState(false);

    const nameChanged = e => {
        let name = e.target.value;
        setName(name);
        setError(name.length === 0);
    };

    const onCreateClick = () => {
        // TODO Make a shared validation function. Test for too long, special characters
        if (name.length > 0) {
            mode === "new" && props.createCategory(name, currentPage.pageID);
            mode === "edit" && props.editCategory(name, currentCategory, currentPage.pageID);
        } else {
            setError(true);
        }
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            onCreateClick();
        }
    };

    const setOpenCategory = category => {
        props.changeCategoryMode(`open-${category.categoryID}`);
    };

    const setEditCategory = category => {
        setCurrentCategory(category.categoryID);
        setName(category.name);
        props.changeCategoryMode("edit");
    };

    const setDeleteConfirmCategory = category => {
        setCurrentCategory(category.categoryID);
        props.changeCategoryMode("delete-confirm");
    };

    const deleteCategory = (category, index) => {
        props.deleteCategory(category.categoryID, index, currentPage.pageID);
    };

    const renderActions = category => {
        if (mode === `open-${category.categoryID}` || mode === `edit-${category.categoryID}`) {
            return <>
                <button key="edit" title="Edit Category Name" onClick={() => setEditCategory(category)} >
                    <i className="fa fa-edit" />
                </button>
                <button key="delete" title="Delete Category" onClick={() => setDeleteConfirmCategory(category)}>
                    <i className="fa fa-trash-alt" />
                </button>
                <button key="cancel" title="Close Actions" onClick={() => props.changeCategoryMode("display")}>
                    <i className="fa fa-chevron-right" />
                </button>
            </>;
        } else {
            return <button className="open" onClick={() => setOpenCategory(category)}>
                <i className="fa fa-chevron-left" />
            </button>;
        }
    };

    const renderCategoryHeader = (category, index, provided) => {
        if (currentCategory === category.categoryID && mode === "edit") { 
            return <div key={category.categoryID}>
                <div className="category-edit inline-form column notification is-primary">{ renderForm() } </div>
            </div>;
        }
        if (currentCategory === category.categoryID && mode === "delete-confirm") { 
            return <div key={category.categoryID}>
                <div className="category-delete-confirm inline-form column notification is-primary">{ renderDeleteConfirm(category, index) } </div>
            </div>;
        }
        return <div className="notification is-primary">
            <span className="drag-handle" {...provided.dragHandleProps}><i className={`fa ${category.dragSaving ? "fa-spinner fa-spin" : "fa-grip-lines" }`} /></span>
            <span className="name">{category.name}</span>

            <div className="category-actions">
                { renderActions(category, index) }
            </div>
        </div>;
    };

    const renderCategories = () => {
        if (categories && categories.length > 0) {
            return <Droppable key={currentPage.pageID} droppableId={currentPage.pageID} type="CATEGORIES">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={`category-list ${snapshot.isDraggingOver ? "dragging-over" : ""}`}>
                        { currentPage.categories.map((pageCategory, index) => {
                            const category = categories.filter(i => i.categoryID === pageCategory)[0];
                            return <Draggable key={category.categoryID} draggableId={category.categoryID} index={index} isDragDisabled={processing || mode !== "display"} type="CATEGORIES">
                                {(provided, snapshot) => (
                                    <div className={`category ${snapshot.isDragging ? "dragging" : ""}`} key={category.categoryID}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        { renderCategoryHeader(category, index, provided) }
                                        { renderFailed() }
                                        <Items items={category.items} categoryID={category.categoryID} />
                                    </div>
                                )}
                            </Draggable>;
                        })}
                        {provided.placeholder}
                    </div>
                    
                )}
            </Droppable>;
        }
    };

    const renderForm = () => {
        const { processing, mode } = props;
        return <>
            { mode === "new" && <label>Create Category:</label>}
            { mode === "edit" && <label>Edit Category Name:</label>}
            <input
                className={`input ${error && "is-danger" }`}
                type="text"
                value={name}
                onChange={ nameChanged }
                onKeyPress={handleKeyPress}
                autoFocus
            />
            <div className="actions">
                { !processing && [
                    <button key="create" className="create" onClick={onCreateClick}><i className="fa fa-check"/></button>,
                    <button key="cancel" className="cancel" onClick={() => props.changeCategoryMode("display")}><i className="fa fa-times"/></button>
                ]}
                { processing &&
                    <i className="fa fa-spinner fa-spin"></i>
                }
            </div>
        </>;
    };
    
    const renderDeleteConfirm = (category, index) => {
        const { processing } = props;
        return <>
            <span>Are you sure you want to delete <b>{category.name}</b> and all the items in it?</span>
            <div className="actions">
                { !processing && [
                    <button key="delete" className="delete-confirm" onClick={() => deleteCategory(category, index)}><i className="fa fa-check"/></button>,
                    <button key="cancel" className="cancel" onClick={() => props.changeCategoryMode("display")}><i className="fa fa-times"/></button>
                ]}
                { processing &&
                    <i className="fa fa-spinner fa-spin"></i>
                }
            </div>
        </>;
    };

    const renderFailed = () => {
        return failed && <div className="failed is-size-7">There was an error with your request. Try again.</div>;
    };

    return userVerified && <div className="categories">
        <div className="categories-list">
            { renderCategories() }
        </div>
        { (mode === "display" || mode === "edit") &&
            <div className="category-form box inline-form">
                <button className="open" onClick={() => {
                    setName("");
                    setError(false);
                    props.changeCategoryMode("new");
                }}>
                    <i className="fa fa-plus" /><label>New Category</label>
                </button>
            </div>
        }
        { mode === "new" && <div className="category-form box inline-form">{renderForm()}</div> }
        { mode === "new" && renderFailed() }
    </div>;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Categories);
