import React from "react";

const GTD = () => {
    return <div>
        <h2 className="is-size-2">Get Things Done</h2>
        <h3 className="is-size-3">Coming Soon</h3>
        <p>All your items will be listed by estimated completion time so you can knock them out.</p>
    </div>;
};

export default GTD;