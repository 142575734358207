import React from "react";
import { Link } from "react-router-dom";

const about = () => (
    <div>
        <h2 className="is-size-2">About Serverless To Do List</h2>
        <p>Serverless is completely built on the cloud using AWS.</p>
        <p>Authentication is handled by <b>Cognito</b>.</p>
        <p>The API layer is handled by <b>API Gateway</b> which triggers <b>Lambda</b> functions.</p>
        <p>Data is stored in <b>Dynamo DB</b>.</p>
        <p>The static assets are stored in <b>S3</b> with traffic being routed by <b>Route 53</b>.</p>
        <p>The front end is <b>React</b> using <b>Redux</b> and <b>React Hooks</b></p>
        <p>The CSS framework is <b>Bulma</b> and the icons are <b>Font Awesome</b></p>
        <p>For more information, or to contribute. please contact <b>Chris</b> at <b>chriskingwebdev@gmail.com</b></p>
        <p><Link key="privacy" to="/privacy">
            <i className="fa fa-user-secret" style={{marginRight:"10px"}} />Privacy Policy
        </Link>
        </p>
    </div>
);
export default about;
