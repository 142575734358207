import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Home from "./home";
import About from "./about";
import Privacy from "./privacy";
import GTD from "./gtd";
import TrashCan from "./trashCan";
import {
    logout,
    login,
    checkLoginStatus
} from "../modules/login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        loggedIn: state.login.loggedIn,
        loginProvider: state.login.loginProvider,
        allLoginsChecked: state.login.allLoginsChecked
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    checkLoginStatus,
    logout,
    login,
    dispatch
}, dispatch);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navActive: false
        };
    }
    componentDidMount() {
        console.log("IN APPMOUNT")
        // this.props.checkLoginStatus();
    }

    renderLogin(provider, icon) {
        const { loggedIn, allLoginsChecked, login } = this.props;
        if (!loggedIn && allLoginsChecked) {
            return <button onClick={() => login(provider.toLowerCase())}><i className={`fab fa-${icon}`} /></button>;
        }
    }

    isCurrentPath = pathToMath => {
        return this.props.location.pathname === pathToMath && "is-active";
    }

    render() {
        const { loggedIn, allLoginsChecked, loginProvider, logout } = this.props;
        const { navActive } = this.state;
        return [
            <nav key="nav" id="navbar" className="navbar has-shadow is-spaced">
                <div className="container">
                    <div className="navbar-brand">
                        <i className="fa fa-server"></i>
                        <i className="fa fa-ban"></i>
                        <Link className="serverless-title" to="/">Serverless<span>To Do List</span></Link>
                        <button onClick={() => this.setState({navActive: !navActive})} className={`navbar-burger burger ${navActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className={`navbar-menu ${navActive ? "is-active" : ""}`}>
                        <div className="navbar-start">
                            <Link className={`navbar-item ${this.isCurrentPath("/")}`} to="/">
                                <i className="fa fa-home" />Home
                            </Link>
                            { loggedIn && [
                                <Link key="gtd" className={`navbar-item ${this.isCurrentPath("/gtd")}`} to="/gtd">
                                    <i className="fa fa-clock" />GTD
                                </Link>,
                                <Link key="trash" className={`navbar-item ${this.isCurrentPath("/trash-can")}`} to="/trash-can">
                                    <i className="fa fa-trash-alt" />Trash Can
                                </Link>,
                                <button key="logout" className="navbar-item" onClick={() => logout(loginProvider)}>
                                    <i className="fa fa-sign-out-alt" />Logout
                                </button>
                            ]}
                            { !loggedIn && allLoginsChecked &&
                                <div key="login" className="navbar-item login">
                                    <label>Login:</label>
                                    { this.renderLogin("Facebook", "facebook") }
                                    { this.renderLogin("Google", "google") }
                                </div>
                            }
                        </div>
                        <div className="navbar-end">
                            <Link to="/about-us" className={`navbar-item ${this.isCurrentPath("/about-us")}`}>
                                <i className="fa fa-cat" />About
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>,
            <main key="main">
                <div className="container">
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about-us" component={About} />
                    <Route exact path="/gtd" component={GTD} />
                    <Route exact path="/trash-can" component={TrashCan} />
                    <Route exact path="/privacy" component={Privacy} />
                </div>
            </main>,
            <footer key="footer">
                <div className="footer-container container">
                    <a href="/privacy">
                        Privacy Policy
                    </a>
                </div>
            </footer>
        ];
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
