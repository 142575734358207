import fetch from "node-fetch";

import { actions } from "../actions";
import { config }  from "../config";
import store from "../stores";
import {checkErrors} from "./shared/checkErrors";

export const itemsAPI = () => next => action => {

    const createItem = async(name, time, dateDue, categoryID, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/items`,{
            method: "POST",
            body: JSON.stringify({name, time, dateDue, categoryID, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.ITEM_CREATE_SUCCESS,
                    item: res.item,
                    categoryID: res.categoryID
                });
            })
            .catch(() => {
                next({
                    type: actions.ITEM_CREATE_FAILURE
                });
            });
    };

    const editItem = async(name, time, dateDue, itemID, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/items`,{
            method: "PUT",
            body: JSON.stringify({name, time, dateDue, itemID, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.ITEM_EDIT_SUCCESS,
                    item: res.item
                });
            })
            .catch(res => {
                next({
                    type: actions.ITEM_EDIT_FAILURE,
                    item: res.item
                });
            });
    };

    const markComplete = async(complete, itemID, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/items/complete`,{
            method: "PUT",
            body: JSON.stringify({complete, itemID, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.ITEM_MARK_COMPLETE_SUCCESS,
                    item: res.item
                });
            })
            .catch(res => {
                next({
                    type: actions.ITEM_MARK_COMPLETE_FAILURE,
                    item: res.item
                });
            });
    };

    const deleteItem = async(itemID, index, categoryID, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/items`,{
            method: "DELETE",
            body: JSON.stringify({itemID, index, categoryID, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.ITEM_DELETE_SUCCESS,
                    item: res.item,
                    categoryID: res.categoryID
                });
            })
            .catch(() => {
                next({
                    type: actions.ITEM_DELETE_FAILURE
                });
            });
    };

    const reorderItem = async({itemID, source, destination, pageID}) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/items/reorder`,{
            method: "PUT",
            body: JSON.stringify({itemID, source, destination, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.ITEM_REORDER_SUCCESS,
                    itemID: res.itemID
                });
            })
            .catch(() => {
                next({
                    type: actions.ITEM_REORDER_FAILURE
                });
            });
    };

    next(action);
    switch (action.type) {
        case actions.ITEM_CREATE: {
            createItem(action.name, action.time, action.dateDue, action.categoryID, action.pageID);
            break;
        }
        case actions.ITEM_EDIT: {
            editItem(action.name, action.time, action.dateDue, action.itemID, action.pageID);
            break;
        }
        case actions.ITEM_MARK_COMPLETE: {
            markComplete(action.complete, action.itemID, action.pageID);
            break;
        }
        case actions.ITEM_DELETE: {
            deleteItem(action.itemID, action.index, action.categoryID, action.pageID);
            break;
        }
        case actions.ITEM_REORDER: {
            reorderItem(action);
            break;
        }
        default:
            break;
    }
};