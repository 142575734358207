import { actions } from "../actions";

const initialState = {
    pages: [],
    mode: "display",
    currentPage: null,
    loadingPage: false,
    processing: false,
    failed: false
};

const getCurrentPage = (pageID, pages) => {
    // return pages.filter(page => page.pageID === pageID)[0];
    const index = pages.findIndex(page => page.pageID === pageID);
    return {...pages[index], index};
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.DONE_USER_INITALIZED:
            return {
                ...state,
                pages: action.pages,
                currentPage: action.pages && {...action.pages[0], index: 0}
            };
        case actions.DONE_LOGOUT:
            return {
                ...initialState
            };
        case actions.PAGE_CHANGE_MODE:
            return {
                ...state,
                mode: action.mode,
                failed: false
            };
        case actions.ITEM_CHANGE_MODE:
        case actions.CATEGORY_CHANGE_MODE:
            return {
                ...state,
                mode: "display",
                failed: false
            };
        case actions.PAGE_CREATE:
        case actions.PAGE_EDIT:
        case actions.PAGE_DELETE:
            return {
                ...state,
                processing: true,
                failed: false
            };
        case actions.PAGE_CREATE_FAILURE:
        case actions.PAGE_EDIT_FAILURE:
        case actions.PAGE_DELETE_FAILURE:
            return {
                ...state,
                processing: false,
                failed: true
            };
        case actions.PAGE_CREATE_SUCCESS: {
            const newPages = [...state.pages, action.page];
            return {
                ...state,
                pages: newPages,
                currentPage: getCurrentPage(action.page.pageID, newPages),
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.PAGE_EDIT_SUCCESS: {
            const updatedPages = state.pages.map(page => {
                if (page.pageID !== action.page.pageID) {
                    return page;
                }
                return {
                    ...page,
                    name: action.page.name
                };
            });
            return {
                ...state,
                pages: updatedPages,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.CATEGORY_CREATE_SUCCESS: {
            const updatedPages = state.pages.map(page => {
                if (page.pageID !== action.pageID) {
                    return page;
                }
                return {
                    ...page,
                    categories: [...page.categories, action.category.categoryID]
                };
            });
            return {
                ...state,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                pages: updatedPages
            };
        }
        case actions.CATEGORY_DELETE_SUCCESS: {
            const updatedPages = state.pages.map(page => {
                if (page.pageID !== action.pageID) {
                    return page;
                }
                const updatedCategories = page.categories.filter(category => {
                    return category !== action.categoryID;
                });
                return {
                    ...page,
                    categories: updatedCategories
                };
            });
            return {
                ...state,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                pages: updatedPages
            };
        }
        case actions.CATEGORY_REORDER: {
            let updatedPages = state.pages.map(page => {
                if (page.pageID !== action.source.droppableId) {
                    return page;
                }
                // remove the category from the source position
                const updatedCategories = page.categories.filter((_, index) => {
                    return index !== action.source.index;
                });
                return {
                    ...page,
                    categories: updatedCategories
                };
            });
            updatedPages = updatedPages.map(page => {
                if (page.pageID !== action.destination.droppableId) {
                    return page;
                }
                // add the item to the destination position
                let newCategories = [...page.categories];
                newCategories.splice(action.destination.index, 0, action.categoryID);
                return {
                    ...page,
                    categories: newCategories
                };
            });
            return {
                ...state,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                pages: updatedPages
            };
        }
        case actions.PAGE_LOAD:
            return {
                ...state,
                mode: "display",
                loadingPage: true,
                failed: false
            };
        case actions.PAGE_LOAD_FAILURE:
            return {
                ...state,
                loadingPage: false,
                failed: true
            };
        case actions.PAGE_LOAD_SUCCESS:
            return {
                ...state,
                loadingPage: false,
                failed: false,
                currentPage: getCurrentPage(action.pageID, state.pages)
            };
        case actions.PAGE_DELETE_SUCCESS: {
            const updatedPages = state.pages.filter(page => {
                return page.pageID !== action.pageID;
            });
            return {
                ...state,
                processing: false,
                failed: false,
                mode: "display",
                pages: updatedPages,
                currentPage: getCurrentPage(action.newPageID, updatedPages)
            };
        }
        case actions.PAGE_REORDER: {
            let targetPage;
            const updatedPages = state.pages.filter(page => {
                if (page.pageID === action.pageID) {
                    targetPage = {
                        ...page,
                        dragSaving: true
                    };
                }
                return page.pageID !== action.pageID;
                
            });
            updatedPages.splice(action.destination.index, 0, targetPage);
            console.log({updatedPages});
            return {
                ...state,
                pages: updatedPages,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                processing: true,
                failed: false
            };
        }
        case actions.PAGE_REORDER_SUCCESS: {
            const updatedPages = state.pages.map(page => {
                if (page.pageID !== action.pageID) {
                    return page;
                }
                return {
                    ...page,
                    dragSaving: false
                };
            });
            return {
                ...state,
                pages: updatedPages,
                currentPage: getCurrentPage(state.currentPage.pageID, updatedPages),
                processing: false,
                failed: false
            };
        }
        default:
            return state;
    }
};

export const changePageMode = mode => {
    return {
        type: actions.PAGE_CHANGE_MODE,
        mode
    };
};

export const createPage = name => {
    return {
        type: actions.PAGE_CREATE,
        name
    };
};

export const editPage = (name, pageID) => {
    return {
        type: actions.PAGE_EDIT,
        name,
        pageID
    };
};

export const loadPage = pageID => {
    return {
        type: actions.PAGE_LOAD,
        pageID
    };
};

export const deletePage = (pageID, index) => {
    return {
        type: actions.PAGE_DELETE,
        pageID,
        index
    };
};

export const reorderPage = (pageID, source, destination) => {
    return {
        type: actions.PAGE_REORDER,
        pageID,
        source,
        destination
    };
};