import { actions } from "../actions";

const initialState = {
    items: [],
    mode: "display",
    processing: false,
    failed: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.DONE_USER_INITALIZED:
            return {
                ...state,
                items: action.items
            };
        case actions.DONE_LOGOUT:
            return {
                ...initialState
            };
        case actions.ITEM_CHANGE_MODE:
            return {
                ...state,
                mode: action.mode,
                failed: false
            };
        case actions.CATEGORY_CHANGE_MODE:
        case actions.PAGE_CHANGE_MODE:
            return {
                ...state,
                mode: "display",
                failed: false
            };
        case actions.ITEM_CREATE:
        case actions.ITEM_EDIT:
        case actions.ITEM_MARK_COMPLETE:
        case actions.ITEM_DELETE:
            return {
                ...state,
                processing: true,
                failed: false
            };
        case actions.ITEM_CREATE_FAILURE:
        case actions.ITEM_EDIT_FAILURE:
        case actions.ITEM_MARK_COMPLETE_FAILURE:
        case actions.ITEM_DELETE_FAILURE:
            return {
                ...state,
                processing: false,
                failed: true
            };
        case actions.ITEM_CREATE_SUCCESS: {
            return {
                ...state,
                items: [...state.items, action.item],
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.ITEM_EDIT_SUCCESS: {
            const updatedItems = state.items.map(item => {
                if (item.itemID !== action.item.itemID) {
                    return item;
                }
                return {
                    ...item,
                    name: action.item.name,
                    time: action.item.time,
                    dateDue: action.item.dateDue
                };
            });
            return {
                ...state,
                items: updatedItems,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.ITEM_MARK_COMPLETE_SUCCESS: {
            const updatedItems = state.items.map(item => {
                if (item.itemID !== action.item.itemID) {
                    return item;
                }
                return {
                    ...item,
                    complete: action.item.complete
                };
            });
            return {
                ...state,
                items: updatedItems,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.ITEM_DELETE_SUCCESS: {
            const updatedItems = state.items.filter(item => {
                return item.itemID !== action.item.itemID;
            });
            return {
                ...state,
                items: updatedItems,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.ITEM_REORDER: {
            const updatedItems = state.items.map(item => {
                if (item.itemID !== action.itemID) {
                    return item;
                }
                return {
                    ...item,
                    dragSaving: true
                };
            });
            return {
                ...state,
                items: updatedItems,
                processing: true,
                failed: false
            };
        }
        case actions.ITEM_REORDER_SUCCESS: {
            const updatedItems = state.items.map(item => {
                if (item.itemID !== action.itemID) {
                    return item;
                }
                return {
                    ...item,
                    dragSaving: false
                };
            });
            return {
                ...state,
                items: updatedItems,
                processing: false,
                failed: false
            };
        }
        case actions.CATEGORY_DELETE_SUCCESS: {
            const updatedItems = state.items.filter(item => {
                return item.categoryID !== action.categoryID;
            });
            return {
                ...state,
                items: updatedItems,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.PAGE_LOAD:
            return {
                ...state,
                mode: "display"
            };
        case actions.PAGE_LOAD_SUCCESS:
        case actions.PAGE_DELETE_SUCCESS:
            return {
                ...state,
                items: action.items
            };
        case actions.PAGE_CREATE_SUCCESS:
            return {
                ...state,
                items: []
            };
        default:
            return state;
    }
};

export const changeItemMode = mode => {
    return {
        type: actions.ITEM_CHANGE_MODE,
        mode
    };
};

export const createItem = (name, time, dateDue, categoryID, pageID) => {
    return {
        type: actions.ITEM_CREATE,
        name,
        time,
        dateDue,
        categoryID,
        pageID
    };
};

export const editItem = (name, time, dateDue, itemID, pageID) => {
    return {
        type: actions.ITEM_EDIT,
        name,
        time,
        dateDue,
        itemID,
        pageID
    };
};

export const markComplete = (complete, itemID, pageID) => {
    return {
        type: actions.ITEM_MARK_COMPLETE,
        complete,
        itemID,
        pageID
    };
};

export const deleteItem = (itemID, index, categoryID, pageID) => {
    return {
        type: actions.ITEM_DELETE,
        itemID,
        index,
        categoryID,
        pageID
    };
};

export const reorderItem = (itemID, source, destination, pageID) => {
    return {
        type: actions.ITEM_REORDER,
        itemID,
        source,
        destination,
        pageID
    };
};

