import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import rootReducer from "./modules";
import { checkLoginStatusAPI } from "./apis/loginAPI";
import { pagesAPI } from "./apis/pagesAPI";
import { categoriesAPI } from "./apis/categoriesAPI";
import { itemsAPI } from "./apis/itemsAPI";

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history),
    checkLoginStatusAPI,
    pagesAPI,
    categoriesAPI,
    itemsAPI
];

if (process.env.NODE_ENV === "development") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
);

export default store;
