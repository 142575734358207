import fetch from "node-fetch";

import { actions } from "../actions";
import { config }  from "../config";
import store from "../stores";
import {checkErrors} from "./shared/checkErrors";

export const categoriesAPI = () => next => action => {

    const createCategory = async(name, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/categories`,{
            method: "POST",
            body: JSON.stringify({name, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.CATEGORY_CREATE_SUCCESS,
                    category: res.category,
                    pageID
                });
            })
            .catch(() => {
                next({
                    type: actions.CATEGORY_CREATE_FAILURE
                });
            });
    };

    const editCategory = async(name, categoryID, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/categories`,{
            method: "PUT",
            body: JSON.stringify({name, categoryID, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.CATEGORY_EDIT_SUCCESS,
                    category: res.category
                });
            })
            .catch(res => {
                next({
                    type: actions.CATEGORY_EDIT_FAILURE,
                    category: res.category
                });
            });
    };

    const reorderCategory = async({categoryID, source, destination, pageID}) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/categories/reorder`,{
            method: "PUT",
            body: JSON.stringify({categoryID, source, destination, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.CATEGORY_REORDER_SUCCESS,
                    categoryID: res.categoryID
                });
            })
            .catch(() => {
                next({
                    type: actions.CATEGORY_REORDER_FAILURE
                });
            });
    };

    const deleteCategory = async({categoryID, index, pageID}) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/categories`,{
            method: "DELETE",
            body: JSON.stringify({categoryID, index, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.CATEGORY_DELETE_SUCCESS,
                    categoryID: res.categoryID,
                    pageID: res.pageID
                });
            })
            .catch(() => {
                next({
                    type: actions.CATEGORY_DELETE_FAILURE
                });
            });
    };

    next(action);
    switch (action.type) {
        case actions.CATEGORY_CREATE: {
            createCategory(action.name, action.pageID);
            break;
        }
        case actions.CATEGORY_EDIT: {
            editCategory(action.name, action.categoryID, action.pageID);
            break;
        }
        case actions.CATEGORY_REORDER: {
            reorderCategory(action);
            break;
        }
        case actions.CATEGORY_DELETE: {
            deleteCategory(action);
            break;
        }
        default:
            break;
    }
};