import fetch from "node-fetch";

import { actions } from "../actions";
import { config }  from "../config";
import store from "../stores";
import {checkErrors} from "./shared/checkErrors";

export const pagesAPI = () => next => action => {

    const createPage = async pageName => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/pages`,{
            method: "POST",
            body: pageName,
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.PAGE_CREATE_SUCCESS,
                    page: res.page
                });
            })
            .catch(() => {
                next({
                    type: actions.PAGE_CREATE_FAILURE
                });
            });
    };

    const editPage = async(name, pageID) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/pages`,{
            method: "PUT",
            body: JSON.stringify({name, pageID}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.PAGE_EDIT_SUCCESS,
                    page: res.page
                });
            })
            .catch(() => {
                next({
                    type: actions.PAGE_EDIT_FAILURE
                });
            });
    };

    const loadPage = async pageID => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/pages/${pageID}`,{
            method: "GET",
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.PAGE_LOAD_SUCCESS,
                    pageID: res.pageID,
                    categories: res.categories,
                    items: res.items
                });
            })
            .catch(() => {
                next({
                    type: actions.PAGE_LOAD_FAILURE
                });
            });
    };

    const deletePage = async(pageID, index) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/pages`,{
            method: "DELETE",
            body: JSON.stringify({pageID, index}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(res => {
                next({
                    type: actions.PAGE_DELETE_SUCCESS,
                    pageID: res.pageID,
                    categories: res.categories,
                    items: res.items,
                    newPageID: res.newPageID
                });
            })
            .catch(() => {
                next({
                    type: actions.PAGE_DELETE_FAILURE
                });
            });
    };

    const reorderPage = async({pageID, source, destination}) => {
        const {apiToken} = store.getState().login;
        await fetch(`${config.ApiEndpoint}/pages/reorder`,{
            method: "PUT",
            body: JSON.stringify({pageID, source, destination}),
            headers: { "X-Amz-Security-Token" : apiToken, "Content-Type": "application/json" },
        })
            .then(res => res.json())
            .then(checkErrors)
            .then(() => {
                next({
                    type: actions.PAGE_REORDER_SUCCESS,
                    pageID: pageID
                });
            })
            .catch(() => {
                next({
                    type: actions.PAGE_REORDER_FAILURE
                });
            });
    };

    next(action);
    switch (action.type) {
        case actions.PAGE_CREATE: {
            createPage(action.name);
            break;
        }
        case actions.PAGE_EDIT: {
            editPage(action.name, action.pageID);
            break;
        }
        case actions.PAGE_LOAD: {
            loadPage(action.pageID);
            break;
        }
        case actions.PAGE_DELETE: {
            deletePage(action.pageID, action.index);
            break;
        }
        case actions.PAGE_REORDER: {
            reorderPage(action);
            break;
        }
        default:
            break;
    }
};