import React from "react";

const TrashCan = () => {
    return <div>
        <h2 className="is-size-2">Trash Can</h2>
        <h3 className="is-size-3">Coming Soon</h3>
        <p>Items will be saved for 30 days and can be restored to an existing category</p>
    </div>;
};

export default TrashCan;