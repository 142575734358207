import { actions } from "../actions";
import base64 from "base-64";

const initialState = {
    loggedIn: false,
    userName: "",
    allLoginsChecked: false,
    loginsChecked: [],
    loginProvider: null,
    userVerified: false
};

const loginsToCheck = 1;

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.DONE_CHECK_LOGIN_STATUS: {
            let newState = { };
            if (action.loggedIn) {
                newState.loginProvider = action.provider;
                newState.loggedIn = true;
            }
            newState.loginsChecked = [...state.loginsChecked, action.provider];
            newState.allLoginsChecked = newState.loginsChecked.length === loginsToCheck;
            return {
                ...state,
                ...newState
            };
        }
        case actions.DONE_GET_COGNITO_CREDENTIALS:
            return {
                ...state,
                apiToken: action.apiToken
            };
        case actions.DONE_LOGOUT:
            return {
                ...state,
                userVerified: false,
                userName: "",
                loggedIn: false,
                allLoginsChecked: true
            };
        case actions.DONE_USER_INITALIZED: {
            const apiProps = JSON.parse(base64.decode(state.apiToken));
            apiProps.timestamp = action.timestamp;
            const apiToken = base64.encode(JSON.stringify(apiProps));
            return {
                ...state,
                userVerified: true,
                apiToken
            };
        }
        default:
            return state;
    }
};

export const checkLoginStatus = (divRef) => {
    return {
        type: actions.CHECK_LOGIN_STATUS,
        divRef
    };
};

export const login = provider => {
    return {
        type: actions.LOGIN,
        provider
    };
};

export const logout = provider => {
    return {
        type: actions.LOGOUT,
        provider
    };
};
