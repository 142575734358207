import React, { useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import {
    checkLoginStatus,
    login,
    logout,
} from "../modules/login";
import {
    reorderItem
} from "../modules/items";
import {
    reorderCategory
} from "../modules/categories";
import {
    reorderPage
} from "../modules/pages";
import Pages from "./pages";
import Categories from "./categories";

const mapDispatchToProps = dispatch => bindActionCreators({
    checkLoginStatus,
    login,
    logout,
    reorderItem,
    reorderCategory,
    reorderPage
}, dispatch);

const mapStateToProps = state => {
    return {
        loggedIn: state.login.loggedIn,
        userName: state.login.userName,
        userVerified: state.login.userVerified,
        allLoginsChecked: state.login.allLoginsChecked,
        loginProvider: state.login.loginProvider,
        currentPage: state.pages.currentPage,
        loadingPage: state.pages.loadingPage
    };
};

const Home = props => {

    const { checkLoginStatus, loggedIn, userName, userVerified, allLoginsChecked, login, reorderItem, reorderCategory, reorderPage, currentPage, loadingPage } = props;

    const divRef = useRef(null);

    useEffect(() => {
        console.log("IN HOMEMOUNT")
        checkLoginStatus(divRef);
    }, [divRef])

    const renderInit = () => {
        if ((!allLoginsChecked && !loggedIn) || (loggedIn && !userVerified)) {
            return <div className="initializing">
                <i className="fa fa-spinner fa-spin"></i>
                <label>Initalizing</label>
            </div>;
        }
    };

    const renderGreeting = () => {
        if (!loggedIn && allLoginsChecked) {
            return <div>
                <h1 className="is-size-1">Welcome to Serverless To Do List</h1>
                <h2 className="is-size-2">All your to dos, in the cloud</h2>
                <p>Please log in using Facebook or Google</p>
            </div>;
        }
    };

    const renderUsername = () => {
        if (userName) {
            return <h1>Hello {userName}</h1>;
        }
    };

    const renderLogin = (provider, icon) => {
        if (!loggedIn && allLoginsChecked) {
            if (provider === "Google") {
                return <div ref={divRef}></div>
            }
            return <div>
                <button className="home-login" onClick={() => login(provider.toLowerCase())}><i className={`fab fa-${icon}`} />{provider}</button>
            </div>;
        }
    };

    const onDragEnd = drag => {
        if (drag.reason === "CANCEL" || !drag.destination || (drag.source.droppableId === drag.destination.droppableId && drag.source.index === drag.destination.index)) return;
        if (drag.type === "ITEMS") {
            reorderItem(drag.draggableId, drag.source, drag.destination, currentPage.pageID);
        } else if (drag.type === "CATEGORIES") {
            reorderCategory(drag.draggableId, drag.source, drag.destination, currentPage.pageID);
        } else if (drag.type === "PAGES") {
            reorderPage(drag.draggableId, drag.source, drag.destination);
        }
    };

    return <>
        { renderInit() }
        { renderGreeting()}
        { renderUsername() }
        <div className="login-buttons">
            { renderLogin("Facebook", "facebook") }
            { renderLogin("Google", "google") }
        </div>
        {loggedIn && <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Pages />
            { !loadingPage ? <Categories /> : <div className="loading-page"><i className="fa fa-spinner fa-spin"></i></div> }
        </DragDropContext>}
    </>;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
