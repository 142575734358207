import { combineReducers } from "redux";
import login from "./login";
import pages from "./pages";
import categories from "./categories";
import items from "./items";

export default combineReducers({
    login,
    pages,
    categories,
    items
});
