import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import {
    createPage,
    changePageMode,
    editPage,
    loadPage,
    deletePage
} from "../modules/pages";

const mapDispatchToProps = dispatch => bindActionCreators({
    createPage,
    changePageMode,
    editPage,
    loadPage,
    deletePage
}, dispatch);

const mapStateToProps = state => {
    return {
        pages: state.pages.pages,
        currentPage: state.pages.currentPage,
        processing: state.pages.processing,
        failed: state.pages.failed,
        mode: state.pages.mode
    };
};


const Pages = props => {
    const [name, setName] = useState("");
    const [error, setError] = useState(false); 
 
    const nameChanged = e => {
        let name = e.target.value;
        setName(name);
        setError(name.length === 0);
    };

    const onCreateClick = () => {
        const { mode, currentPage } = props;
        // TODO Make a shared validation function. Test for too long, special characters
        if (name.length > 0) {
            mode === "new" && props.createPage(name);
            mode === "edit" && props.editPage(name, currentPage.pageID);
        } else {
            setError(true);
        }
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            onCreateClick();
        }
    };

    const renderPages = () => {
        const { pages, mode, currentPage, loadPage, processing }  = props;
        if (pages && pages.length > 0 && mode !== "new" && mode !== "edit" && mode !== "confirm-delete") {
            return <Droppable key="PAGES" droppableId="PAGES" type="PAGES" direction="horizontal">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={`pages-list column ${snapshot.isDraggingOver ? "dragging-over" : ""}`}>
                        {
                            pages.map((page, index) => {
                                return <Draggable key={page.pageID} draggableId={page.pageID} index={index} isDragDisabled={processing || mode !== "display"} type="PAGES">
                                    {(provided, snapshot) => (
                                        <a
                                            key={page.pageID}
                                            onClick={() => loadPage(page.pageID)}
                                            className={`page box ${snapshot.isDragging ? "dragging" : ""} ${page.pageID === currentPage.pageID && "current"}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            {page.name}
                                            <span className="drag-handle" {...provided.dragHandleProps}><i className={`fa ${page.dragSaving ? "fa-spinner fa-spin" : "fa-grip-lines-vertical" }`} /></span>
                                        </a>
                                    )}
                                </Draggable>;
                            })
                        }
                        {provided.placeholder}
                        {renderPageActions()}
                    </div>
                )}
            </Droppable>;
        }
    };

    const renderForm = () => {
        const { processing, mode } = props;
        if (mode === "new" || mode === "edit") {
            return <div className="page-form column">
                <div className="box inline-form">
                    { mode === "new" && <label>Create Page:</label>}
                    { mode === "edit" && <label>Edit Page Name:</label>}
                    <input
                        className={`input ${error && "is-danger" }`}
                        type="text"
                        value={name}
                        onChange={ nameChanged }
                        onKeyPress={handleKeyPress}
                        autoFocus
                    />
                    <div className="actions">
                        { !processing && [
                            <button key="create" className="create" onClick={onCreateClick}><i className="fa fa-check"/></button>,
                            <button key="cancel" className="cancel" onClick={() => props.changePageMode("display")}><i className="fa fa-times"/></button>
                        ]}
                        { processing &&
                            <i className="fa fa-spinner fa-spin"></i>
                        }
                    </div>
                </div>
            </div>;
        }
    };

    const renderConfirmDelete = () => {
        const { processing, mode, currentPage } = props;
        if (mode === "confirm-delete") {
            return <div className="page-delete-confirm column">
                <div className="box inline-form">
                    <span>Are you sure you want to delete <b>{currentPage.name}</b> and all the categories and items within?</span>
                    <div className="actions">
                        { !processing && [
                            <button key="create" className="create" onClick={() => props.deletePage(currentPage.pageID, currentPage.index)}><i className="fa fa-check"/></button>,
                            <button key="cancel" className="cancel" onClick={() => props.changePageMode("display")}><i className="fa fa-times"/></button>
                        ]}
                        { processing &&
                            <i className="fa fa-spinner fa-spin"></i>
                        }
                    </div>
                </div>
            </div>;
        }
    };

    const renderPageActions = () => {
        const { pages, mode, currentPage }  = props;
        if (pages && pages.length > 0 && mode !== "confirm-delete") {
            return <div className="pages-actions column is-2-desktop">
                <div className={`box ${(mode === "display" || mode === "new") && "box-closed"}`}>
                    { (mode === "display" || mode === "new") && [
                        <button key="open" className="open" title="Open Actions" onClick={() => props.changePageMode("open")}>
                            <i className="fa fa-chevron-left"></i>
                        </button>
                    ]}
                    {
                        (mode === "open" || mode === "edit" || mode === "confirm-delete") && <>
                            <button key="new" className="new" title="New Page" onClick={() => {
                                setName("");
                                setError(false);
                                props.changePageMode("new");
                            }}>
                                <i className="far fa-file"></i>
                                <i className="fa fa-plus"></i>
                            </button>
                            <button key="edit" title="Edit Page Name" onClick={() => {
                                setName(currentPage.name);
                                props.changePageMode("edit");
                            }} >
                                <i className="fa fa-edit" />
                            </button>
                            <button key="delete" title="Delete Page" onClick={() => props.changePageMode("confirm-delete")}>
                                <i className="fa fa-trash-alt" />
                            </button>
                            <button key="cancel" title="Close Actions" onClick={() => props.changePageMode("display")}>
                                <i className="fa fa-times" />
                            </button>
                        </>
                    }
                </div>
            </div>;
        }
    };

    const renderFailed = () => {
        const { failed } = props;
        return failed && <div className="failed is-size-7">There was an error with your request. Try again.</div>;
    };


    return <div className="pages">
        <div className="columns is-mobile">
            { renderPages() }
            { renderForm() }
            { renderConfirmDelete() }
        </div>
        { renderFailed() }
    </div>;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pages);
