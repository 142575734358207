import { actions } from "../actions";

const initialState = {
    categories: [],
    mode: "display",
    processing: false,
    failed: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.DONE_USER_INITALIZED:
            return {
                ...state,
                categories: action.categories
            };
        case actions.DONE_LOGOUT:
            return {
                ...initialState
            };

        case actions.CATEGORY_CHANGE_MODE:
            return {
                ...state,
                mode: action.mode,
                failed: false
            };
        case actions.ITEM_CHANGE_MODE:
        case actions.PAGE_CHANGE_MODE:
            return {
                ...state,
                mode: "display",
                failed: false
            };
        case actions.CATEGORY_CREATE:
        case actions.CATEGORY_EDIT:
        case actions.CATEGORY_DELETE:
            return {
                ...state,
                processing: true,
                failed: false
            };
        case actions.CATEGORY_CREATE_FAILURE:
        case actions.CATEGORY_EDIT_FAILURE:
        case actions.CATEGORY_DELETE_FAILURE:
            return {
                ...state,
                processing: false,
                failed: true
            };
        case actions.CATEGORY_CREATE_SUCCESS:
            return {
                ...state,
                categories: [...state.categories, action.category],
                processing: false,
                failed: false,
                mode: "display"
            };
        case actions.CATEGORY_EDIT_SUCCESS: {
            const updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.category.categoryID) {
                    return category;
                }
                return {
                    ...category,
                    name: action.category.name
                };
            });
            return {
                ...state,
                categories: updatedCategories,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.CATEGORY_DELETE_SUCCESS: {
            const updatedCategories = state.categories.filter(category => {
                return category.categoryID !== action.categoryID;
            });
            return {
                ...state,
                categories: updatedCategories,
                processing: false,
                failed: false,
                mode: "display"
            };
        }
        case actions.ITEM_CREATE_SUCCESS: {
            const updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.categoryID) {
                    return category;
                }
                return {
                    ...category,
                    items: [...category.items, action.item.itemID]
                };
            });
            return {
                ...state,
                categories: updatedCategories
            };
        }
        case actions.ITEM_DELETE_SUCCESS: {
            const updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.categoryID) {
                    return category;
                }
                const updatedItems = category.items.filter(item => {
                    return item !== action.item.itemID;
                });
                return {
                    ...category,
                    items: updatedItems
                };
            });
            return {
                ...state,
                categories: updatedCategories
            };
        }
        case actions.ITEM_REORDER: {
            let updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.source.droppableId) {
                    return category;
                }
                // remove the item from the source position
                const updatedItems = category.items.filter((_, index) => {
                    return index !== action.source.index;
                });
                return {
                    ...category,
                    items: updatedItems
                };
            });
            updatedCategories = updatedCategories.map(category => {
                if (category.categoryID !== action.destination.droppableId) {
                    return category;
                }
                // add the item to the destination position
                let newItems = [...category.items];
                newItems.splice(action.destination.index, 0, action.itemID);
                return {
                    ...category,
                    items: newItems
                };
            });
            return {
                ...state,
                categories: updatedCategories
            };
        }
        case actions.CATEGORY_REORDER: {
            const updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.categoryID) {
                    return category;
                }
                return {
                    ...category,
                    dragSaving: true
                };
            });
            return {
                ...state,
                categories: updatedCategories,
                processing: true,
                failed: false
            };
        }
        case actions.CATEGORY_REORDER_SUCCESS: {
            const updatedCategories = state.categories.map(category => {
                if (category.categoryID !== action.categoryID) {
                    return category;
                }
                return {
                    ...category,
                    dragSaving: false
                };
            });
            return {
                ...state,
                categories: updatedCategories,
                processing: false,
                failed: false
            };
        }
        case actions.PAGE_LOAD:
            return {
                ...state,
                mode: "display"
            };
        case actions.PAGE_LOAD_SUCCESS:
        case actions.PAGE_DELETE_SUCCESS:
            return {
                ...state,
                categories: action.categories
            }; 
        case actions.PAGE_CREATE_SUCCESS:
            return {
                ...state,
                categories: []
            };
        default:
            return state;
    }
};

export const changeCategoryMode = mode => {
    return {
        type: actions.CATEGORY_CHANGE_MODE,
        mode
    };
};

export const createCategory = (name, pageID) => {
    return {
        type: actions.CATEGORY_CREATE,
        name,
        pageID
    };
};

export const editCategory = (name, categoryID, pageID) => {
    return {
        type: actions.CATEGORY_EDIT,
        name,
        categoryID,
        pageID
    };
};

export const deleteCategory = (categoryID, index, pageID) => {
    return {
        type: actions.CATEGORY_DELETE,
        categoryID,
        index,
        pageID
    };
};

export const reorderCategory = (categoryID, source, destination, pageID) => {
    return {
        type: actions.CATEGORY_REORDER,
        categoryID,
        source,
        destination,
        pageID
    };
};
