import {config as AWSconfig, CognitoIdentityCredentials} from "aws-sdk/global";
import fetch from "node-fetch";
import base64 from "base-64";

import { actions } from "../actions";
import { config }  from "../config";

let divRef = null;

(function(d, s, id) {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.1&appId=356070668261738&autoLogAppEvents=1"; // prod
    js.target = "_TOP";
    fjs.parentNode.insertBefore(js, fjs);
}(document, "script", "facebook-jssdk"));
(function() {
    let po = document.createElement("script"); po.type = "text/javascript"; po.async = false;
    po.src = "https://accounts.google.com/gsi/client";
    let s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(po, s);
})();

const initFacebook = () => {
    if (global.FB) {
        global.FB.init({
            appId      : "356070668261738", // prod
            cookie     : true,
            xfbml      : true,
            version    : "v3.1"
        });
    } else {
        setTimeout(initFacebook,1);
    }
};
initFacebook();

export const checkLoginStatusAPI = () => next => action => {

    let FBTimeout;
    let gotFacebook = false;

    const facebookStatusChange = response => {
        clearTimeout(FBTimeout);
        // console.log({fbresponce:response});
        if (!gotFacebook) {
            gotFacebook = true;
            if (response.status === "connected" && response.authResponse) {
                doneLogin("facebook", true);
                getCognitoCreds("graph.facebook.com", response.authResponse.accessToken);
            } else {
                doneLogin("facebook", false);
                initGoogleClient();
            }
        }
    };

    const loadFacebookClient = () => {
        // console.log("IN loadFacebookClient");
        // console.log({globalFB: global.FB});
        if (global.FB) {
            // console.log("ABOUT TO getLoginStatus");
            // console.log({getLoginStatus: global.FB.getLoginStatus});
            // console.log({facebookStatusChange});
            global.FB.getLoginStatus(facebookStatusChange);
            FBTimeout = setTimeout(() => {
                global.FB.getLoginStatus(facebookStatusChange);
            }, 500);
        } else {
            setTimeout(loadFacebookClient,50);
        }
    };

    const doneLogin = (provider, loggedIn) => {
        next({
            type: actions.DONE_CHECK_LOGIN_STATUS,
            provider,
            loggedIn
        });
    };

    const getCognitoCreds = (account, token) => {
        let logins = {};
        logins[account] = token;
        let props = {
            IdentityPoolId: "us-west-2:9ae390cc-4bc7-49af-8aa0-e07e4932aa68",
            Logins: logins
        };
        AWSconfig.credentials = new CognitoIdentityCredentials(props);

        AWSconfig.credentials.get(() => {
            let apiProps = {
                IdentityId: AWSconfig.credentials.identityId,
                Logins: logins,
                token
            };

            let apiToken = base64.encode(JSON.stringify(apiProps));
            initDBUser(apiToken);
            next({
                type: actions.DONE_GET_COGNITO_CREDENTIALS,
                cognitoID: AWSconfig.credentials.identityId,
                apiToken
            });
        });
    };

    const initDBUser = async apiToken => {
        const res = await fetch(`${config.ApiEndpoint}/init_user`,{
            method: "GET",
            headers: { "X-Amz-Security-Token" : apiToken },
        }).then(res => res.json());
        // console.log({res});
        next({
            type: actions.DONE_USER_INITALIZED,
            ...res
        });
    };

    const initGoogleClient = () => {
        try {
        window.google.accounts.id.initialize({
            "client_id": "775459840977-t5403sqkmkt1it98hfiu1k1ji64jvumm.apps.googleusercontent.com",
            callback: res => {
                googleStatusChange(res.credential);
            },
          });
          window.google.accounts.id.renderButton(divRef.current, {});
        }  catch (error) {

            console.log({ error });
          }
    };

    const googleStatusChange = credentials => {
        debugger;
        if (credentials) {
            doneLogin("google", true);
            getCognitoCreds("accounts.google.com", credentials);
        } else {
            doneLogin("google", false);
        }
    };

    next(action);
    switch (action.type) {
        case actions.CHECK_LOGIN_STATUS: {
            console.log("STARTING CHECK_LOGIN_STATUS");
            divRef = action.divRef;
            loadFacebookClient();
            break;
        }
        case actions.LOGIN:
            switch (action.provider) {
                case "facebook":
                    global.FB.login(response => {
                        if (response.authResponse) {
                            let accessToken = response.authResponse.accessToken;
                            getCognitoCreds("graph.facebook.com", accessToken);
                            doneLogin("facebook", true);
                        }
                    }, { scope: "email" });
                    break;
                // case "google": {
                //     global.gapi.auth2.getAuthInstance().signIn().then(() => {
                //         googleStatusChange(true);
                //     });
                //     break;
                // }
                default:
                    break;
            }
            break;
        case actions.LOGOUT:
            switch (action.provider) {
                case "facebook":
                    global.FB.logout(() => {
                        AWSconfig.credentials.clearCachedId();
                        next({
                            type: actions.DONE_LOGOUT
                        });
                    });
                    break;
                case  "google":
                    debugger;
                    google.accounts.id.disableAutoSelect()
                    // global.gapi.auth2.getAuthInstance().signOut();
                    AWSconfig.credentials.clearCachedId();
                    next({
                        type: actions.DONE_LOGOUT
                    });
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
};
