export const checkErrors = res => {
    return new Promise((resolve, reject) => {
        if (res.status === false) {
            reject(res);
        } else if (res.status === "timestamp-expired") {
            reject(res);
            window.location.reload();
            // TODO: change this to re-request the current page and update the user timestamp
        } else {
            resolve(res);
        }
    });
};