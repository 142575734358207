import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./stores.js";
import App from "./containers/app.js";

import "./styles/styles.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

// import "./index.css";
import { unregister } from "./registerServiceWorker";

import {config} from "aws-sdk/global";
config.region = "us-west-2";

const target = document.querySelector("#root");

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <App />
            </div>
        </ConnectedRouter>
    </Provider>,
    target
);
unregister();
