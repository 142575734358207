import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Draggable, Droppable } from "react-beautiful-dnd";

import "react-datepicker/dist/react-datepicker.css";

import {
    changeItemMode,
    createItem,
    editItem,
    markComplete,
    deleteItem
} from "../modules/items";

const mapDispatchToProps = dispatch => bindActionCreators({
    changeItemMode,
    createItem,
    editItem,
    markComplete,
    deleteItem
}, dispatch);

const mapStateToProps = state => { 
    return {
        allItems: state.items.items,
        currentPage: state.pages.currentPage,
        mode: state.items.mode,
        processing: state.items.processing,
        failed: state.items.failed,
    };
};

const Items = props => {

    // TODO Make enter button submit form
    // TODO Consider a separate hover and edit state for time and dateDue (like the old one)

    const { items, allItems, categoryID, mode, processing, currentPage, failed } = props;

    const [name, setName] = useState("");
    const [time, setTime] = useState();
    const [dateDue, setDateDue] = useState();
    const [currentItem, setCurrentItem] = useState("");
    const [error, setError] = useState(false);

    const timeValues = {
        "0": "",
        "1": "5 min",
        "2": "15 min",
        "3": "30 min",
        "4": "45 min",
        "5": "1 hr",
        "6": "1.5 hr",
        "7": "2 hr",
        "8": "3 hr",
        "9": "4 hr",
        "10": "4+ hr"
    };

    const renderTimeDropDown = () => {
        return Object.keys(timeValues).map(key => <option key={key} value={key}>{timeValues[key]}</option>);
    };

    const renderDueDate = dueDate => {
        let className = "";
        let text = "";
        if (dueDate) {
            const dueDateDate = new Date(dueDate);
            const difference = Math.ceil((dueDateDate - new Date()) / (1000 * 60 * 60 * 24));
            switch (true) {
                case difference < 0:
                    className = "red4";
                    text = `Due ${0 - difference} Days Ago`;
                    break;
                case difference === 0:
                    className = "red3";
                    text = "Due Today";
                    break;
                case difference === 1:
                    className = "red2";
                    text = "Due Tomorrow";
                    break;
                case difference <= 7:
                    className = "red1";
                    text = `Due ${dueDateDate.toLocaleDateString("en-US", { weekday: "long" })}`;
                    break;
                case difference > 7:
                    text = `Due ${dueDateDate.toLocaleDateString("en-US", {month: "short", day: "numeric"})}`;
                    break;
                default:
                    break;
            }
        }

        return <label className={`date ${className}`}>{text}</label>;
    };

    const onCreateClick = () => {
        // TODO Make a shared validation function. Test for too long, special characters
        if (name.length > 0) {
            mode === `new-${categoryID}` && props.createItem(name, time, dateDue, categoryID, currentPage.pageID);
            mode === `edit-${currentItem}` && props.editItem(name, time, dateDue, currentItem, currentPage.pageID);
        } else {
            setError(true);
        }
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            onCreateClick();
        }
    };

    const setEditItem = item => {
        setCurrentItem(item.itemID);
        setName(item.name);
        setTime(item.time);
        setDateDue(item.dateDue && new Date(item.dateDue));
        props.changeItemMode(`edit-${item.itemID}`);
    };

    const setOpenItem = item => {
        props.changeItemMode(`open-${item.itemID}`);
    };

    const markComplete = item => {
        props.markComplete(!item.complete, item.itemID, currentPage.pageID);
    };

    const deleteItem = (item, index) => {
        props.deleteItem(item.itemID, index, categoryID, currentPage.pageID);
    };

    const renderActions = (item, index) => {
        if (mode === `open-${item.itemID}` || mode === `edit-${item.itemID}`) {
            return <>
                <button key="edit" title="Edit Item Name" onClick={() => setEditItem(item)} >
                    <i className="fa fa-edit" />
                </button>
                { (mode === `open-${item.itemID}` && processing) ? <button><i className="fa fa-spinner fa-spin" /></button> :
                    <>
                        <button key="completed" title={`Mark Item ${item.complete ? "Incomplete" : "Completed"}`} onClick={() => markComplete(item)}>
                            <i className={`fa fa-thumbs-${item.complete ? "down" : "up"}`} />
                        </button>
                        <button key="delete" title="Delete Item" onClick={() => deleteItem(item, index)}>
                            <i className="fa fa-trash-alt" />
                        </button>
                    </>
                }
                <button key="cancel" title="Close Actions" onClick={() => props.changeItemMode("display")}>
                    <i className="fa fa-chevron-right" />
                </button>
            </>;
        } else {
            return <button className="open" onClick={() => setOpenItem(item)}>
                <i className="fa fa-chevron-left" />
            </button>;
        }
    };

    const renderItem = (itemID, index) => {
        const item = allItems.filter(i => i.itemID === itemID)[0];
        return <Draggable key={item.itemID} draggableId={item.itemID} index={index} isDragDisabled={processing || mode !== "display"} type="ITEMS">
            {(provided, snapshot) => (
                <div className={`item ${snapshot.isDragging ? "dragging" : ""}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    { mode === `edit-${item.itemID}` && <div className="item-form inline-form edit-form">
                        { renderForm() }
                    </div> }
                    { mode !== `edit-${item.itemID}` && <>
                        <span className="drag-handle" {...provided.dragHandleProps}><i className={`fa ${item.dragSaving ? "fa-spinner fa-spin" : "fa-grip-lines" }`} /></span>
                        <span className="complete">{item.complete && <i className="fa fa-thumbs-up" />}</span>
                        <div className="text-info">
                            <label className="name">{item.name}</label>
                            <label className="time">{timeValues[item.time]}</label>
                            { renderDueDate(item.dateDue) }
                        </div>
                    </> }
                    <div className="item-actions">
                        { renderActions(item, index) }
                    </div>
                </div>
            )}
        </Draggable>;
    };

    const renderForm = () => {
        return <>
            { mode === `new-${categoryID}` && <h3>Create Item:</h3>}
            <div className="name">
                <label>Name:</label>
                <input
                    className={`input ${error && "is-danger" }`}
                    type="text" value={name}
                    onChange={e => {
                        setName(e.target.value);
                        setError(e.target.value.length === 0);
                    }}
                    onKeyPress={handleKeyPress}
                    autoFocus
                />
            </div>
            <div className="time">
                <label>Time:</label>
                <div className="select">
                    <select
                        value={time}
                        onChange={e => setTime(e.target.value)}
                        onKeyPress={handleKeyPress}
                    >
                        { renderTimeDropDown() }
                    </select>
                </div>
            </div>
            <div className="date">
                <label>Due Date:</label>
                <DatePicker
                    className="input"
                    selected={dateDue}
                    onChange={e => setDateDue(e)}
                />
            </div>
            <div className="actions">
                { !processing && [
                    <button key="create" className="create" onClick={onCreateClick}><i className="fa fa-check"/></button>,
                    <button key="cancel" className="cancel" onClick={() => props.changeItemMode("display")}><i className="fa fa-times"/></button>
                ]}
                { mode.indexOf("open") === -1 && processing &&
                    <i className="fa fa-spinner fa-spin"></i>
                }
            </div>
        </>;
    };

    const renderFailed = () => {
        return failed && <div className="failed is-size-7">There was an error with your request. Try again.</div>;
    };

    return <div className="items">
        <Droppable key={categoryID} droppableId={categoryID} type="ITEMS">
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className={`item-list ${snapshot.isDraggingOver ? "dragging-over" : ""}`}>
                    { items.map((item, index) => {
                        return renderItem(item, index);
                    })}
                    { items.length === 0 && <div className="empty"></div> }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
        <div className="item-form inline-form">
            { (mode !== `new-${categoryID}`) && <button className="open" onClick={() => {
                setName("");
                setTime();
                setDateDue();
                setError(false);
                props.changeItemMode(`new-${categoryID}`);
            }}>
                <i className="fa fa-plus" /><label>New Item</label>
            </button>}
            { mode === `new-${categoryID}` && renderForm() }
        </div>
        { mode === `new-${categoryID}` && renderFailed() }
    </div>;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Items);